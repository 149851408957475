import { getFeaturePlan } from '@readme/iso';
import React, { useContext } from 'react';
import { useWatch } from 'react-hook-form';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';
import useProjectPlan from '@core/hooks/useProjectPlan';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore } from '@core/store';

import PlanAccessBadge from '@routes/SuperHub/components/PlanAccess/Badge';
import PayGate from '@routes/SuperHub/components/PlanAccess/PayGate';
import { Fieldset, FormRow, Page, PageContent } from '@routes/SuperHub/Settings/components';

import Flex from '@ui/Flex';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';
import Select from '@ui/Select';
import Textarea from '@ui/Textarea';
import Toggle from '@ui/Toggle';

import { useProjectSettingsFormContext } from '../Context';

/**
 * Configuration form for project settings.
 */
function ProjectSettings() {
  const uid = useUniqueId('ProjectSettings');
  const { control } = useProjectSettingsFormContext();
  const {
    project: { fullBaseUrl },
  } = useContext(ProjectContext) as ProjectContextValue;
  const initialSitemapFormValue = useProjectStore(s => s.initialData.sitemap);
  const { planFeatures, activePlan } = useProjectPlan();

  const privacyView = useWatch({ control, name: 'privacy.view' });
  const hasInternalDocsAccess = planFeatures.password;
  const hasCustomLoginAccess = activePlan === 'enterprise';
  const internalDocsPlan = getFeaturePlan('password');

  return (
    <Page>
      <PageContent>
        <Fieldset legend="Settings">
          <FormRow columns={2}>
            <RHFGroup control={control} id={uid('form-name')} label="Name" name="name" required>
              {({ field }) => <Input {...field} size="sm" />}
            </RHFGroup>

            {/* TODO: RM-10574 Disabling this field until we support Suggested Edits in SuperHub  
            <RHFGroup
              control={control}
              helpMessage="Allow users to suggest edits to your documentation."
              label="Suggested Edits"
              name="suggested_edits"
            >
              {({ field }) => (
                <Toggle
                  {...field}
                  checked={field.value === 'enabled'}
                  isLabelMuted
                  label={
                    <span>
                      <strong>Allow</strong> suggested edits
                    </span>
                  }
                  onChange={event => {
                    field.onChange(event.target.checked ? 'enabled' : 'disabled');
                  }}
                  size="sm"
                />
              )}
            </RHFGroup> */}

            <RHFGroup
              control={control}
              helpMessage="Project description is used in the page meta description and is seen by search engines and sites like Facebook."
              id={uid('form-description')}
              label="Description"
              name="description"
            >
              {({ field }) => <Textarea {...field} rows={3} size="sm" />}
            </RHFGroup>
          </FormRow>
        </Fieldset>

        <Fieldset legend="Metadata">
          <FormRow columns={2}>
            <RHFGroup
              control={control}
              helpMessage={
                'The URL for your company\'s main website. We\'ll link to it in various places so people can "Go Home".'
              }
              id={uid('form-homepage-url')}
              isUrl
              label="Main URL"
              name="homepage_url"
            >
              {({ field }) => <Input {...field} placeholder="https://" size="sm" />}
            </RHFGroup>
            <RHFGroup
              control={control}
              helpMessage="Canonical URL is set by default to your project's base URL. Override the default setting with this field."
              id={uid('form-canonical-url')}
              isUrl
              label="Canonical URL"
              name="canonical_url"
            >
              {({ field }) => <Input {...field} placeholder={fullBaseUrl ?? 'https://'} size="sm" />}
            </RHFGroup>
          </FormRow>
          <FormRow columns={2}>
            <RHFGroup control={control} label="Robots.txt" name="allow_crawlers">
              {({ field }) => (
                <Toggle
                  {...field}
                  checked={field.value === 'enabled'}
                  isLabelMuted
                  label={
                    <span>
                      <strong>Allow</strong> indexing by robots
                    </span>
                  }
                  onChange={event => {
                    field.onChange(event.target.checked ? 'enabled' : 'disabled');
                  }}
                  size="sm"
                />
              )}
            </RHFGroup>
            <RHFGroup
              control={control}
              description={
                initialSitemapFormValue === 'enabled' && (
                  <span>
                    <span>Available at </span>
                    <a href="/sitemap.xml" rel="noreferrer" target="_blank">{`${window.location.host}/sitemap.xml`}</a>
                  </span>
                )
              }
              helpMessage={'Make publicly available a "sitemap.xml" directory for your project.'}
              label="Sitemaps"
              name="sitemap"
            >
              {({ field }) => (
                <Toggle
                  {...field}
                  checked={field.value === 'enabled'}
                  isLabelMuted
                  label={
                    <span>
                      <strong>Publish </strong> sitemap.xml
                    </span>
                  }
                  onChange={event => {
                    field.onChange(event.target.checked ? 'enabled' : 'disabled');
                  }}
                  size="sm"
                />
              )}
            </RHFGroup>
          </FormRow>
        </Fieldset>

        <Fieldset
          legend={
            <Flex align="center" gap="sm" justify="start">
              Internal Documentation{' '}
              <PlanAccessBadge
                access={hasInternalDocsAccess ? 'open' : 'locked'}
                plan={internalDocsPlan}
                tooltip={true}
              />
            </Flex>
          }
        >
          <PayGate access={hasInternalDocsAccess ? 'open' : 'locked'}>
            <FormRow columns={2}>
              <RHFGroup
                control={control}
                description={
                  !!privacyView &&
                  {
                    public: 'Site is available to the public.',
                    admin: 'Site is only available to users that have project permissions.',
                    password: 'Site is gated behind a password authentication system.',
                    custom_login:
                      'Users who view your site will be forwarded to a URL of your choice, having them login there and be forwarded back to your ReadMe site.',
                  }[privacyView]
                }
                id={uid('form-privacy-view')}
                label="Protection Type"
                name="privacy.view"
              >
                {({ field }) => (
                  <Select
                    {...field}
                    options={[
                      {
                        label: 'Public',
                        value: 'public',
                      },
                      {
                        label: 'Project Members Only',
                        value: 'admin',
                      },
                      {
                        label: 'Password Protected',
                        value: 'password',
                      },
                      ...(hasCustomLoginAccess
                        ? [
                            {
                              label: 'Custom Login',
                              value: 'custom_login',
                            },
                          ]
                        : []),
                    ]}
                    size="sm"
                  />
                )}
              </RHFGroup>
              {privacyView === 'password' && (
                <RHFGroup
                  control={control}
                  id={uid('form-sitewide-password')}
                  label="Site Password"
                  name="privacy.password"
                  required
                >
                  {({ field }) => <Input {...field} size="sm" type="password" />}
                </RHFGroup>
              )}
              {privacyView === 'custom_login' && (
                <RHFGroup
                  control={control}
                  id={uid('form-custom-login-url')}
                  isUrl
                  label="Custom Login URL"
                  name="custom_login.login_url"
                  required
                >
                  {({ field }) => <Input {...field} placeholder="https://" size="sm" />}
                </RHFGroup>
              )}
            </FormRow>
          </PayGate>
        </Fieldset>

        {/* TODO: RM-10710 Disabling this until we have API support for Exporting/Importing/Cloning git-backed projects    
        <Fieldset legend="Management">
          <FormRow columns={2}>
            <FormGroup label="Export Data">
              <Flex>
                <Button kind="secondary" size="sm">
                  <Icon name="upload" />
                  Export Docs
                </Button>
              </Flex>
            </FormGroup>
            <FormGroup label="Import Data">
              <Flex>
                <Button kind="secondary" size="sm">
                  <Icon name="download" />
                  Import Docs
                </Button>
              </Flex>
            </FormGroup>
          </FormRow>
          <FormRow columns={2}>
            <FormGroup helpMessage="Clone your project to another subdomain." label="Clone Project">
              <Flex>
                <Button kind="secondary" size="sm">
                  <Icon name="clipboard" />
                  Clone Project
                </Button>
              </Flex>
            </FormGroup>
            <FormGroup helpMessage="Delete entire project and all associated data." label="Delete Project">
              <Flex>
                <Button kind="destructive" size="sm">
                  <Icon name="trash" />
                  Delete Project
                </Button>
              </Flex>
            </FormGroup>
          </FormRow>
        </Fieldset> */}
      </PageContent>
    </Page>
  );
}

export default ProjectSettings;
