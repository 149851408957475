import type { ProjectSettingsFormValues } from '../Context';
import type { Path } from 'react-hook-form';

import React, { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useProjectStore } from '@core/store';

import DemoGate from '@routes/SuperHub/components/PlanAccess/DemoGate';
import {
  Fieldset,
  FormRow,
  LockedField,
  PageContent,
  PreviewRow,
  LayoutLinksSVG,
  LayoutDropdownSVG,
  Page,
} from '@routes/SuperHub/Settings/components';

import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Radio from '@ui/Radio';
import { RHFGroup } from '@ui/RHF';
import Select from '@ui/Select';
import Toggle from '@ui/Toggle';

import { useProjectSettingsFormContext, useFormGateContext } from '../Context';
import useLiveUpdateProjectStore from '../useLiveUpdateProjectStore';

import classes from './index.module.scss';
import NavList from './NavList';
import NavSection from './NavSection';

const fields: Path<ProjectSettingsFormValues>[] = [
  'appearance.navigation.logo_link',
  'appearance.navigation.first_page',
  'appearance.navigation.subheader_layout',
  'appearance.navigation.version',
  'appearance.navigation.right',
  'appearance.navigation.left',
  'appearance.navigation.sub_nav',
  'appearance.navigation.links',
];

export default function Navigation() {
  const bem = useClassy(classes, 'Navigation');
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { hasOneChild, parent } = project;
  const homepageUrl = useProjectStore(s => s.data.homepage_url);
  const { control } = useProjectSettingsFormContext();
  const formGate = useFormGateContext();

  useLiveUpdateProjectStore({ fields, control });

  const linkLogoToURL = useWatch({ control, name: 'appearance.navigation.logo_link' });

  return (
    <Page>
      <PageContent isCondensed>
        <DemoGate access={formGate === 'demo' ? 'locked' : 'open'} feature="navigation">
          <Fieldset size="sm">
            <FormRow>
              <RHFGroup
                control={control}
                label="Logo Link"
                name="appearance.navigation.logo_link"
                size="sm"
                warningMessage={
                  linkLogoToURL === 'homepage' && !homepageUrl ? (
                    <span>
                      You haven&apos;t set a Main URL set. Please configure it on the{' '}
                      <Link to="/configure/project-settings">Settings Page</Link>.
                    </span>
                  ) : null
                }
              >
                {({ field }) => (
                  <Select
                    {...field}
                    options={[
                      { label: 'Landing Page', value: 'landing_page' },
                      { label: 'Main URL', value: 'homepage' },
                    ]}
                    size="sm"
                  />
                )}
              </RHFGroup>

              <RHFGroup
                control={control}
                description="First page users see visiting your site"
                label="Landing Page"
                name="appearance.navigation.first_page"
                size="sm"
              >
                {({ field }) =>
                  !hasOneChild ? (
                    <Select
                      {...field}
                      options={[
                        { label: 'Home', value: 'landing_page' },
                        { label: 'Guides (default)', value: 'documentation' },
                        { label: 'API Reference', value: 'reference' },
                      ]}
                      size="sm"
                    />
                  ) : (
                    <LockedField>
                      <p>
                        Managed by <strong>{parent?.subdomain}</strong>
                      </p>
                      <p>
                        Administrators of {parent?.subdomain} can access the{' '}
                        <a href={`/group/${parent?.subdomain}`} target="_self">
                          Global Landing Page settings
                        </a>
                      </p>
                    </LockedField>
                  )
                }
              </RHFGroup>

              <RHFGroup control={control} label="Version" name="appearance.navigation.version" size="sm">
                {({ field }) => (
                  <Toggle
                    {...field}
                    checked={field.value === 'disabled'}
                    isLabelMuted
                    onChange={ev => {
                      field.onChange(ev.target.checked ? 'disabled' : 'enabled');
                    }}
                    size="sm"
                  >
                    <span>
                      <strong>Hide</strong> version in subnavigation
                    </span>
                  </Toggle>
                )}
              </RHFGroup>

              <RHFGroup control={control} label="Layout" name="appearance.navigation.subheader_layout" size="sm">
                {({ field }) => (
                  <PreviewRow>
                    <Radio {...field} checked={field.value === 'links'} isCard label="Links" value="links">
                      <LayoutLinksSVG />
                    </Radio>
                    <Radio {...field} checked={field.value === 'dropdown'} isCard label="Dropdown" value="dropdown">
                      <LayoutDropdownSVG />
                    </Radio>
                  </PreviewRow>
                )}
              </RHFGroup>
            </FormRow>
          </Fieldset>

          <Fieldset legend="Left Section" size="sm">
            <FormRow columns={2}>
              <FormGroup size="sm">
                <NavList name="appearance.navigation.left" />
              </FormGroup>
            </FormRow>
          </Fieldset>
          <Fieldset legend="Right Section" size="sm">
            <FormRow columns={2}>
              <FormGroup size="sm">
                <NavList name="appearance.navigation.right" />
              </FormGroup>
            </FormRow>
          </Fieldset>
          <Fieldset legend="Subnavigation" size="sm">
            <FormRow columns={2} formGroupGap={0}>
              <FormGroup size="sm">
                <Flex align="stretch" gap="xs" layout="col">
                  <NavSection name="appearance.navigation.links.home" />
                  <NavSection name="appearance.navigation.links.guides" />
                  <NavSection name="appearance.navigation.links.reference" />
                  <NavSection name="appearance.navigation.links.recipes" />
                  <NavSection name="appearance.navigation.links.changelog" />
                  <NavSection name="appearance.navigation.links.discussions" />
                  <hr className={bem('-rule')} />
                  <NavList itemTypeOptions={['link_url', 'custom_page']} name="appearance.navigation.sub_nav" />
                </Flex>
              </FormGroup>
            </FormRow>
          </Fieldset>
        </DemoGate>
      </PageContent>
    </Page>
  );
}
