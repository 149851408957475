import type { SidebarNavLinkProps } from '../components/SidebarNav/Link';

import React from 'react';
import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { PLAN_UPGRADE_URL } from '@core/constants/urls';
import useClassy from '@core/hooks/useClassy';
import useProjectPlan from '@core/hooks/useProjectPlan';

import BasePanel from '@routes/SuperHub/components/BasePanel';
import { SidebarNav, SidebarNavSection, SidebarNavLink } from '@routes/SuperHub/Settings/components';
import { SuperHubHashRoutePaths, type SuperHubHashRouteParams } from '@routes/SuperHub/types';

import GitConnection from '../forms/GitConnection';
import ProjectSettingsForm from '../forms/Project';
import CustomLogin from '../forms/Project/CustomLogin';
import HealthCheck from '../forms/Project/HealthCheck';
import Integrations from '../forms/Project/Integrations';
import ProjectSettings from '../forms/Project/ProjectSettings';

import styles from './index.module.scss';

const configureNavData = [
  {
    section: 'Project',
    items: [
      {
        icon: 'book-open',
        label: 'Project Settings',
        to: '/configure/project-settings',
      },
      {
        icon: 'pie-chart',
        label: 'Documentation Metrics',
        href: '/dash?to=metrics/page-views',
      },
      {
        icon: 'alert-triangle',
        label: 'Error Pages',
        href: '/dash?to=errors',
        planFeature: 'errors',
      },
      {
        icon: 'radio',
        label: 'Health Check',
        to: '/configure/health-check',
        planFeature: 'healthCheck',
      },
    ],
  },
  {
    section: 'Access',
    items: [
      {
        icon: 'key',
        label: 'API Keys',
        href: '/dash?to=api-key',
        target: '_blank',
      },
      {
        icon: 'teammates',
        label: 'Manage Team',
        href: '/dash?to=users',
        target: '_blank',
      },
      {
        icon: 'unlock',
        label: 'Custom Login',
        to: '/configure/custom-login',
      },
      {
        icon: 'lock',
        label: 'SAML Authentication',
        href: '/dash?to=saml',
        target: '_blank',
      },
    ],
  },
  {
    section: 'Admin',
    items: [
      {
        icon: 'credit-card',
        label: 'Manage Plan',
        href: PLAN_UPGRADE_URL,
      },
      {
        icon: 'globe',
        label: 'Custom Domain',
        href: '/dash?to=domains',
        target: '_blank',
        planFeature: 'custom_domain',
      },
      {
        icon: 'minimize-2',
        label: 'Integrations',
        to: '/configure/integrations',
      },
      {
        icon: 'labs',
        label: 'Labs',
        href: '/dash?to=labs',
        target: '_blank',
      },
      {
        icon: 'git-branch',
        isBeta: true,
        label: 'Git Connection',
        to: '/configure/git-connection',
      },
    ],
  },
] satisfies { items: SidebarNavLinkProps[]; section: string }[];

function Configure() {
  const bem = useClassy(styles, 'Configure');

  const { pathname } = useLocation();
  const { planFeatures } = useProjectPlan();

  const match = matchPath<SuperHubHashRouteParams>(pathname, Object.values(SuperHubHashRoutePaths));
  const action = match?.params.action;

  return (
    <BasePanel label="Project settings controls">
      <div className={bem('-layout')} data-testid="configure-panel">
        <SidebarNav className={bem('-sidebar')}>
          {action === 'configure' && (
            <>
              {configureNavData.map(({ section, items }) => (
                <SidebarNavSection key={section} label={section}>
                  {items.map((props: SidebarNavLinkProps) => (
                    <SidebarNavLink key={`configure-${section}-${props.label}`} {...props} />
                  ))}
                </SidebarNavSection>
              ))}
            </>
          )}
        </SidebarNav>

        {/* Routes to render sub-forms, grouped by sections in alphabetical order for readability */}
        <Switch>
          <Route path="/:action(configure)/:section(custom-login)">
            <ProjectSettingsForm>
              <CustomLogin />
            </ProjectSettingsForm>
          </Route>
          <Route path="/configure/git-connection">
            <GitConnection />
          </Route>
          <Route path="/:action(configure)/:section(health-check)">
            <ProjectSettingsForm formGate={planFeatures.healthCheck ? 'none' : 'plan'}>
              <HealthCheck />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(configure)/:section(integrations)">
            <ProjectSettingsForm>
              <Integrations />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(configure)/:section(project-settings)">
            <ProjectSettingsForm>
              <ProjectSettings />
            </ProjectSettingsForm>
          </Route>
          <Route exact path="/:action(configure)">
            <Redirect to="/configure/project-settings" />
          </Route>
        </Switch>
      </div>
    </BasePanel>
  );
}

export default Configure;
